//REACT IMPORTS
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

//IMPORT PAGES
import HomePage from "./pages/home_page.pages";
import RegisterPage from "./pages/register_page.pages";
import PlayerRanking from "./pages/player_ranking.pages";
import UnAuthorizedPage from "./pages/unauthorized_page.pages";
//IMPORT LAYOUT
import RootLayout from "./layout/root_layout.layout";

import "./App.css";
import PrivateRoutes from "./utils/PrivateRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./pages/error_page.pages";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<HomePage />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/register" element={<RegisterPage />} />
      </Route>
      <Route path="/playerranking" element={<PlayerRanking />} />
      <Route path="/unauthorized" element={<UnAuthorizedPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Route>
  )
);
function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
