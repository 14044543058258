import React, { createContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
export const PlayerContext = createContext();

export const PlayerProvider = ({ children }) => {
  const [data, setData] = useState([]);
  // EDIT PLAYER DATA
  const updatePlayerData = async (updatedPlayer, callback) => {
    try {
      const response = await axios.put(
        `https://badmintonnepal.org/api/admin/updatePlayer/${updatedPlayer.id}`,
        updatedPlayer,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(`Player Edited Successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setData((prevData) =>
          prevData.map((item) =>
            item.id === updatedPlayer.id ? { ...item, ...updatedPlayer } : item
          )
        );
        callback();
      }
    } catch (err) {
      toast.error("Unable To Edit", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  // DELETE PLAYER DATA
  const deletePlayerData = async (playerId) => {
    try {
      const response = await axios.delete(
        `https://badmintonnepal.org/api/admin/deletePlayer/${playerId}`,
        { withCredentials: true }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(`Player Deleted Successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      const updatedPlayers = data.filter((player) => player.id !== playerId);
      setData(updatedPlayers);
    } catch (err) {
      toast.error("Unable To Delete", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  // ADD PLAYER POINT
  const addPlayerPoint = async (playerId, point, callback) => {
    try {
      const response = await axios.put(
        `https://badmintonnepal.org/api/admin/updatePlayerPoint/${playerId}`,
        { point: point },
        { withCredentials: true }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(`Point Added Successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setData((prevData) =>
          prevData.map((item) =>
            item.id === playerId
              ? { ...item, point: parseInt(item.point) + parseInt(point) }
              : item
          )
        );

        callback();
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable To Add Point", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <PlayerContext.Provider
      value={{
        data,
        setData,
        updatePlayerData,
        deletePlayerData,
        addPlayerPoint,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
