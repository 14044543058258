import React from "react";
import PlayerRankingContent from "../components/player_ranking/player_ranking_main_section";
function PlayerRanking() {
  return (
    <div>
      <PlayerRankingContent />
    </div>
  );
}

export default PlayerRanking;
