import { useEffect } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
//IMPORT COMPONENTS
import NavBar from "../components/nav_bar/nav_bar.components";
function RootLayout() {
  const { setIsAuthenticated } = useAuth();
  useEffect(() => {
    async function checkAuthentication() {
      try {
        const response = await axios.get(
          "https://badmintonnepal.org/api/admin/isLoggedIn",
          { withCredentials: true }
        );
        if (response.status === 200) setIsAuthenticated(true);
      } catch (err) {
        console.log(err);
      }
    }
    checkAuthentication();
  }, [setIsAuthenticated]);
  return (
    <div>
      <NavBar />
      <Outlet />
    </div>
  );
}

export default RootLayout;
