import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import NbaLogo from "../../assets/nba_logo.png";
import { Button, Box, Typography } from "@mui/material";
import "./nav_bar.styles.css";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import LogoutIcon from "@mui/icons-material/Logout";
function NavBar() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  async function handleLogout() {
    try {
      const response = await axios.post(
        "https://badmintonnepal.org/api/admin/logout"
      );
      if (response.status === 200) {
        setIsAuthenticated(false);

        toast.success(`Logout Success`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/", { replace: true });
      }
    } catch (err) {
      toast.error(`Unable To Logout`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  return (
    <div className="navbar">
      <Box display="flex" alignItems="center" margin="auto" gap="1rem">
        <Link to="/">
          <img src={NbaLogo} alt="logo" />
        </Link>
        <Link to="/" style={{ textDecoration: "none" }}>
          <h3>Nepal Badminton Association</h3>
        </Link>
      </Box>
      {isAuthenticated && (
        <Box>
          <Button
            variant="contained"
            onClick={handleLogout}
            size="small"
            endIcon={<LogoutIcon fontSize="string" />}
            sx={{
              padding: { md: "4px 16px", xs: "4px 8px" },
              backgroundColor: "#A91720",
              "&:hover": {
                backgroundColor: "#8f141c",
              },
            }}
          >
            <Typography sx={{ fontSize: { md: "14px", xs: "10px" } }}>
              Logout
            </Typography>
          </Button>
        </Box>
      )}
    </div>
  );
}

export default NavBar;
