import React, { useEffect, useState } from "react";
import axios from "axios";
import "./player_ranking.styles.css";
import RankingFilter from "./player_ranking_filter";
import RankingTable from "./player_ranking_table";
import usePlayerData from "../../hooks/usePlayerData";
import { CircularProgress } from "@mui/material";
// Sample data for demonstration

export default function PlayerRankingMainSection() {
  const [errorMsg, setErrorMsg] = useState("");
  const { setData } = usePlayerData();
  const [isLoading, setIsLoading] = useState(false);
  const [pdfCategory, setPdfCategory] = useState("All");
  const API_URL = "https://badmintonnepal.org/api/admin/getPlayers";
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(API_URL, {
          withCredentials: true,
        });
        if (response.status === 200) {
          setData(response.data);
          setErrorMsg("");
        }
      } catch (err) {
        if (err.response) {
          setErrorMsg(err.response.data.message);
        } else {
          setErrorMsg(err.message);
        }
        console.log(err);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [setData]);

  return (
    <div className="ranking__content">
      <div className="ranking__content--title">
        <h4
          style={{
            padding: "0 0.6rem 0.2rem 0.6rem",
            borderBottom: "2px solid white",
            display: "inline-block",
          }}
        >
          Player Ranking List
        </h4>
      </div>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(7, 7, 7, 0.04)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress sx={{ color: "#861ED8" }} />
        </div>
      )}
      {errorMsg ? (
        <div>
          <h1>Unable to fetch data </h1>
          <p>{errorMsg}</p>
        </div>
      ) : (
        <div>
          <div>
            <RankingFilter setPdfCategory={setPdfCategory} />
          </div>
          <div>
            <RankingTable pdfCategory={pdfCategory} />
          </div>
        </div>
      )}
    </div>
  );
}
