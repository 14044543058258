import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from "@mui/icons-material/Key";
import EmailIcon from "@mui/icons-material/Email";
import LoginIcon from "@mui/icons-material/Login";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
function AdminLoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { setIsAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://badmintonnepal.org/api/admin/login`,
        {
          email,
          password,
        }
      );

      if (response.status === 200) {
        setIsAuthenticated(true);
        toast.success(`Login Success`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/", { replace: true });
      }
    } catch (err) {
      if (err.response) {
        setErrorMsg(err.response.data.message);
      } else {
        setErrorMsg(err.message);
      }
      console.log(err);
      console.log(err.message);

      toast.error(`${errorMsg}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsLoading(false);
  };

  return (
    <Box>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(7, 7, 7, 0.04)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress sx={{ color: "#861ED8" }} />
        </div>
      )}
      <form onSubmit={handleLoginSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          justifyContent="center"
        >
          <TextField
            label="Email"
            type="email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Password"
            type="password"
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            variant="contained"
            style={{
              width: "100px",
              alignSelf: "center",
              backgroundColor: "#022362",
            }}
            endIcon={<LoginIcon />}
          >
            Login
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default AdminLoginForm;
