import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
import axios from "axios";
function RegistrationForm() {
  const [image, setImage] = useState(null);
  const [imagePreview, setPreviewImage] = useState();
  const [category, setCategory] = useState("all");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [clubDistrict, setClubDistrict] = useState("");
  const [points, setPoints] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (!image) {
      setPreviewImage(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreviewImage(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);
  const PHONE_REGEX = /^[0-9]{10}$/;

  function handleImageUpload(event) {
    if (!event.target.files || event.target.files.length === 0) {
      setImage("");
      return;
    }

    setImage(event.target.files[0]);
  }
  async function handleRegistration(e) {
    e.preventDefault();
    if (phoneNumber) {
      if (!PHONE_REGEX.test(phoneNumber)) {
        toast.warn(`Please enter a valid phone number`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
    }
    if (
      category === "mixedDouble" ||
      category === "mensDoubles" ||
      category === "womensDoubles"
    ) {
      if (!name.includes("/")) {
        toast.warn("Please enter two players name separated by /", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
    }

    const formData = new FormData();
    formData.append("image", image);
    formData.append("category", category);
    formData.append("name", name);
    formData.append("phone_number", phoneNumber);
    formData.append("club", clubDistrict);
    formData.append("point", points);

    //send form data to backend
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://badmintonnepal.org/api/admin/playerRegister",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(`Player Registered Successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setImage(null);
        setCategory("all");
        setName("");
        setPhoneNumber(null);
        setClubDistrict("");
        setPoints(null);
      }
    } catch (err) {
      console.log(err);
      if (err?.response) {
        setErrorMsg(err.response.data.message);
      } else {
        setErrorMsg(err.message);
      }
      toast.error(`${errorMsg}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsLoading(false);
  }
  return (
    <Box position="relative">
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(7, 7, 7, 0.04)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress sx={{ color: "#861ED8" }} />
        </div>
      )}

      <div>
        <form onSubmit={handleRegistration}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
            marginBottom="1.5rem"
          >
            <div>
              <h4>Upload Image</h4>
              <label
                htmlFor="image-upload"
                style={{
                  display: "block",
                  width: "130px",
                  height: "140px",
                  border: "1px solid #ccc",
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: "#D9D9D9",
                  borderTopRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
              >
                {image ? (
                  <img
                    src={imagePreview}
                    alt="Uploaded"
                    style={{
                      width: "100%",
                      height: "100%",
                      content: "contain",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <AddPhotoAlternateIcon />
                  </div>
                )}
              </label>
              <input
                type="file"
                id="image-upload"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
            </div>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <div className="select-wrapper">
                  <label htmlFor="category" style={{ color: "white" }}>
                    Category
                  </label>
                  <div className="select-container">
                    <select
                      id="category"
                      name="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="mixedDouble">Mixed Doubles</option>
                      <option value="mensSingle">Men's Single</option>
                      <option value="womensSingle">Women's Single</option>
                      <option value="mensDoubles">Men's Double</option>
                      <option value="womensDoubles">Women's Double</option>
                      <option value="U-17GirlsSingle">
                        U-17 Girl’s Singles{" "}
                      </option>
                      <option value="U-17BoysSingle">U-17 Boy’s Singles</option>
                      <option value="U-15GirlsSingle">
                        {" "}
                        U-15 Girl’s Singles
                      </option>
                      <option value="U-15BoysSingle">U-15 Boy’s Singles</option>
                    </select>
                    <div className="icon-container">
                      <ArrowDropDownIcon
                        style={{
                          color: "#476076",
                          borderLeft: "2px solid #476076",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className="modal__input">
                  <label htmlFor="name" style={{ color: "white" }}>
                    Name
                  </label>
                  <div className="modal__input--container">
                    <input
                      type="text"
                      min="0"
                      id="name"
                      placeholder="Ram / Sita"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className="modal__input">
                  <label htmlFor="contact" style={{ color: "white" }}>
                    Contact
                  </label>
                  <div className="modal__input--container">
                    <input
                      type="number"
                      min="0"
                      id="contact"
                      placeholder="Your Phone Number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className="modal__input">
                  <label htmlFor="clubDistrict" style={{ color: "white" }}>
                    Club/District
                  </label>
                  <div className="modal__input--container">
                    <input
                      type="text"
                      min="0"
                      id="clubDistrict"
                      placeholder="Your Club or District"
                      value={clubDistrict}
                      onChange={(e) => setClubDistrict(e.target.value)}
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div className="modal__input">
                  <label htmlFor="points" style={{ color: "white" }}>
                    Point
                  </label>
                  <div className="modal__input--container">
                    <input
                      type="number"
                      min="0"
                      id="points"
                      placeholder="Your Point"
                      value={points}
                      onChange={(e) => setPoints(e.target.value)}
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
            <Button variant="contained" type="submit">
              Register
            </Button>
          </Box>
        </form>
      </div>
    </Box>
  );
}
export default RegistrationForm;
