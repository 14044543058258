import React from "react";
import HomeContent from "../components/home_page/home_content_main_section";
function HomePage() {
  return (
    <div>
      <HomeContent />
    </div>
  );
}

export default HomePage;
