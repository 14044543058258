import React, { useState } from "react";
import axios from "axios";
import { Box, Button, IconButton, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import usePlayerData from "../../hooks/usePlayerData";
import { toast } from "react-toastify";
function RankingFilter({ setPdfCategory }) {
  const { setData } = usePlayerData();
  const [category, setCategory] = useState("all");
  const [name, setName] = useState("all");
  const [point, setPoint] = useState(null);
  const [topList, setTopList] = useState(20);
  const API_URL = "https://badmintonnepal.org/api/admin/getPlayers?1=1";
  var QUERY = "";
  const handleSearch = async (e) => {
    e.preventDefault();
    setPdfCategory(category);
    if (category !== "all" && category !== "") {
      QUERY = `&category=${category}`;
    }
    if (name !== "all" && name !== "") {
      QUERY = QUERY + `&name=${name}`;
    }
    if (point !== null) {
      QUERY = QUERY + `&sort=${point}`;
    }
    if (topList !== 20) {
      QUERY = QUERY + `&limit=${topList}`;
    }
    try {
      const response = await axios.get(API_URL + QUERY);
      setData(response.data);
    } catch (err) {
      console.log(err);
      toast.error("Unable To Filter", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Box>
      <form>
        <Box display="flex" justifyContent="flex-start">
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Grid item>
              <div className="select-wrapper">
                <label htmlFor="category" style={{ color: "white" }}>
                  Category
                </label>
                <div className="select-container">
                  <select
                    id="category"
                    name="category"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <option value="">All</option>
                    <option value="mixedDouble">Mixed Doubles</option>
                    <option value="mensSingle">Men's Single</option>
                    <option value="womensSingle">Women's Single</option>
                    <option value="mensDoubles">Men's Double</option>
                    <option value="womensDoubles">Women's Double</option>
                    <option value="U-17GirlsSingle">
                      U-17 Girl’s Singles{" "}
                    </option>
                    <option value="U-17BoysSingle">U-17 Boy’s Singles</option>
                    <option value="U-15GirlsSingle">
                      {" "}
                      U-15 Girl’s Singles
                    </option>
                    <option value="U-15BoysSingle">U-15 Boy’s Singles</option>
                  </select>
                  <div className="icon-container">
                    <ArrowDropDownIcon
                      style={{
                        color: "#476076",
                        borderLeft: "2px solid #476076",
                      }}
                    />
                  </div>
                </div>
              </div>{" "}
            </Grid>
            <Grid item>
              <div className="modal__input">
                <label htmlFor="name" style={{ color: "white" }}>
                  Name
                </label>
                <div className="modal__input--container">
                  <input
                    type="text"
                    min="0"
                    id="name"
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>{" "}
            </Grid>
            <Grid item>
              <div className="modal__input">
                <label htmlFor="points" style={{ color: "white" }}>
                  Points
                </label>
                <div
                  style={{
                    background: "white",
                    height: "40px",
                    display: "flex",
                  }}
                >
                  <IconButton onClick={() => setPoint("point_desc")}>
                    <KeyboardArrowUpIcon
                      className={point === "point_desc" ? "active__point" : ""}
                    />
                  </IconButton>
                  <IconButton onClick={() => setPoint("point_asc")}>
                    <KeyboardArrowDownIcon
                      className={point === "point_asc" ? "active__point" : ""}
                    />
                  </IconButton>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="select-wrapper">
                <label htmlFor="category" style={{ color: "white" }}>
                  Top List
                </label>
                <div className="select-container">
                  <select
                    id="category"
                    name="category"
                    value={topList}
                    onChange={(e) => setTopList(e.target.value)}
                  >
                    <option value="10">Top 10</option>
                    <option value="20">Top 20</option>
                    <option value="30">Top 30</option>
                    <option value="40">Top 40</option>
                    <option value="50">Top 50</option>
                    <option value="all">ALL</option>
                  </select>
                  <div className="icon-container">
                    <ArrowDropDownIcon
                      style={{
                        color: "#476076",
                        borderLeft: "2px solid #476076",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  alignSelf: "flex-end",
                  color: "white",
                  borderColor: "white",
                  marginBottom: "0.2rem",
                }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
}

export default RankingFilter;
