import React from "react";
import { Box, Typography, Grid } from "@mui/material";
function PlayerView({ viewItem }) {
  return (
    <Grid
      container
      justifyContent="space-evenly"
      alignItems="center"
      gap="1rem"
    >
      {/* IMAGE */}
      <Grid item>
        <Box>
          <img
            src={
              `https://badmintonnepal.org/api/${viewItem?.player_image}` || ""
            }
            alt="player"
            loading="lazy"
            style={{
              width: "150px",
              height: "120px",
              borderRadius: "6px",
            }}
          />
        </Box>
      </Grid>
      {/* PLAYER DETAILS */}
      <Grid item>
        <Grid container flexDirection="column" spacing="1rem">
          <Grid item>
            <Grid container spacing="1rem">
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgb(248,248,248)",
                    width: "110px",
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    padding: "0.3rem",
                    borderRadius: "10px",
                  }}
                >
                  <Typography sx={{ color: "black", fontWeight: "bold" }}>
                    Name
                  </Typography>
                  <Typography sx={{ color: "black", alignSelf: "flex-end" }}>
                    {viewItem?.name || ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgb(248,248,248)",
                    padding: "0.3rem",
                    borderRadius: "10px",
                    width: "110px",
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography sx={{ color: "black", fontWeight: "bold" }}>
                    Club/District
                  </Typography>
                  <Typography sx={{ color: "black", alignSelf: "center" }}>
                    {viewItem?.club || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing="1rem">
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgb(248,248,248)",
                    padding: "0.3rem",
                    borderRadius: "10px",
                    width: "110px",
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography sx={{ color: "black", fontWeight: "bold" }}>
                    Point
                  </Typography>
                  <Typography sx={{ color: "black", alignSelf: "center" }}>
                    {viewItem?.point || ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgb(248,248,248)",
                    padding: "0.3rem",
                    borderRadius: "10px",
                    width: "110px",
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography sx={{ color: "black", fontWeight: "bold" }}>
                    Rank
                  </Typography>
                  <Typography sx={{ color: "black", alignSelf: "center" }}>
                    {viewItem?.player_rank || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing="1rem">
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgb(248,248,248)",
                    padding: "0.3rem",
                    borderRadius: "10px",
                    width: "110px",
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography sx={{ color: "black", fontWeight: "bold" }}>
                    Contact
                  </Typography>
                  <Typography sx={{ color: "black", alignSelf: "center" }}>
                    {viewItem?.phone_number || ""}
                  </Typography>
                </Box>{" "}
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgb(248,248,248)",
                    padding: "0.3rem",
                    borderRadius: "10px",
                    width: "110px",
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography sx={{ color: "black", fontWeight: "bold" }}>
                    Category
                  </Typography>
                  <Typography sx={{ color: "black", alignSelf: "center" }}>
                    {viewItem?.category || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PlayerView;
