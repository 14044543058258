import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, DialogContent, Slide } from "@mui/material";
import LoginForm from "../components/login_form/login_form";
import useAuth from "../hooks/useAuth";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function UnAuthorizedPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-container": {
          justifyContent: "center",
          alignItems: "center",

          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <DialogContent>
        <LoginForm />
      </DialogContent>
    </Dialog>
  );
}

export default UnAuthorizedPage;
