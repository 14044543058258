import RegisterForm from "./register_content_form";
import "./register.styles.css";
function RegisterMainSection() {
  return (
    <div className="register__content">
      <div className="register__content--title">
        <h4>Player Registration</h4>
      </div>
      <div>
        <RegisterForm />
      </div>
    </div>
  );
}

export default RegisterMainSection;
