import React from "react";
import RegisterContent from "../components/register_page/register_content_main_section";
function LoginPage() {
  return (
    <div>
      <RegisterContent />
    </div>
  );
}
export default LoginPage;
