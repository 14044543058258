import { createContext, useState } from "react";
// import axios from "axios";
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // useEffect(() => {
  //   async function checkAuthentication() {
  //     try {
  //       const response = await axios.get(
  //         "https://badmintonnepal.org/api/admin/isLoggedIn",
  //         { withCredentials: true }
  //       );
  //       if (response.status === 200) setIsAuthenticated(true);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   checkAuthentication();
  // }, []);
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
