import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import "./home.styles.css";

function HomeMainSection() {
  return (
    <div className="home__content">
      <div className="home__content--title">
        <h4>NBA Ranking</h4>
      </div>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        gap="3rem"
        justifyContent="center"
        alignItems="center"
        // className="home__content--buttons"
      >
        <Link
          to="register"
          className="home__content--buttons_button1 home__content--buttons_button"
        >
          Registration
        </Link>
        <Link
          to="playerranking"
          className="home__content--buttons_button2 home__content--buttons_button"
        >
          Player Rank
        </Link>
      </Box>
    </div>
  );
}

export default HomeMainSection;
