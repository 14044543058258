import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Pagination,
  styled,
  Dialog,
  DialogContent,
  Slide,
  DialogActions,
  TextField,
  Popover,
  IconButton,
  InputAdornment,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PlayerEditForm from "./player_ranking_edit_form";
import ViewPlayer from "./player_ranking_view";
import useAuth from "../../hooks/useAuth";
import usePlayerData from "../../hooks/usePlayerData";
import nbaLogo from "../../assets/nba_logo.png";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const RankingTable = ({ pdfCategory }) => {
  const { isAuthenticated } = useAuth();
  const { data } = usePlayerData();
  const { deletePlayerData, addPlayerPoint } = usePlayerData();

  // PAGINATOIN
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  //FOR POINT ADDITION
  const [anchorEl, setAnchorEl] = useState(null);
  const [addPoint, setAddPoint] = useState("");
  const [addPointId, setAddPointId] = useState(null);
  let categoryText = pdfCategory.replace(/([A-Z])/g, " $1").toUpperCase();
  const open = Boolean(anchorEl);
  const addPointPopoverId = open ? "popup-button" : undefined;
  // FOR EDITING
  const [editItem, setEditItem] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  //For Deletion
  const [deleteItemId, setDeleteItemId] = useState(null);
  //For VIEWING
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewItem, setViewItem] = useState(null);
  //FOR CREATING PDF COLUMNS
  const columns = [
    { title: "Rank", field: "player_rank" },
    { title: "Name", field: "name" },
    { title: "Club/District", field: "club" },
    { title: "Points", field: "point" },
  ];
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleDownloadPDF = () => {
    const date = new Date();
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("NBA Ranking", 100, 20, {
      align: "center",
    });
    doc.setFontSize(12);
    doc.text(`${categoryText}`, 100, 30, {
      align: "center",
    });

    doc.setFontSize(10);

    doc.text(
      `Date: ${date.getFullYear()} / ${
        date.getMonth() + 1
      } / ${date.getDate()}`,
      180,
      37,
      {
        align: "right",
      }
    );
    doc.autoTable({
      margin: { top: 40 },
      theme: "grid",
      styles: { halign: "center" },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 12,
      },
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data,
    });
    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.addImage(nbaLogo, "png", 133, finalY + 23, 10, 10, { align: "right" });
    doc.setFontSize(12);
    doc.text("Nepal Badminton Association", 200, finalY + 30, {
      align: "right",
    });
    doc.save("player_data.pdf");
  };

  const handleAddPointClose = () => {
    setAnchorEl(null);
    setAddPoint("");
    setAddPointId(null);
  };

  const handlePlayerDeletion = (id) => {
    deletePlayerData(id);
    setDeleteItemId(null);
  };
  const handleAddPoint = () => {
    if (!addPoint || !addPointId) {
      return;
    }
    addPlayerPoint(addPointId, addPoint, handleAddPointClose);
  };
  const StyledPagination = styled(Pagination)`
    && .MuiPaginationItem-root {
      color: #ffffff; /* Change the color here */
    }
  `;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="2rem"
      sx={{ overflow: "auto" }}
    >
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Rank</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Club/District</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Points</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Contact</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.player_rank}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.club}</TableCell>
                  <TableCell>{item.point}</TableCell>
                  <TableCell>{item.phone_number}</TableCell>
                  <TableCell>
                    <Box display="flex" gap="0.8rem" alignItems="flex-end">
                      {/* EDIT DIALOG */}
                      {isAuthenticated && (
                        <Button
                          size="small"
                          variant="outlined"
                          startIcon={<CreateIcon />}
                          style={{
                            color: "#861ED8",
                            borderColor: "#861ED8",
                          }}
                          onClick={() => {
                            setEditItem(item);
                            setOpenEditDialog(true);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                      {/* DELETE BUTTON */}
                      {isAuthenticated && (
                        <span>
                          {deleteItemId === item.id ? (
                            <Box display="flex" gap="0.8rem">
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={{
                                  maxWidth: "40px",
                                  maxHeight: "30px",
                                  minWidth: "40px",
                                  minHeight: "30px",
                                }}
                                onClick={() => handlePlayerDeletion(item.id)}
                              >
                                <CheckIcon fontSize="small" />
                                {/* Sure */}
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                style={{
                                  maxWidth: "40px",
                                  maxHeight: "30px",
                                  minWidth: "40px",
                                  minHeight: "30px",
                                }}
                                onClick={() => setDeleteItemId(null)}
                              >
                                <CloseIcon fontSize="small" />
                              </Button>
                            </Box>
                          ) : (
                            <Button
                              size="small"
                              variant="outlined"
                              startIcon={<DeleteForeverIcon />}
                              color="secondary"
                              style={{
                                color: "#FF0000",
                                borderColor: "#FF0000",
                              }}
                              onClick={() => setDeleteItemId(item.id)}
                            >
                              Delete
                            </Button>
                          )}
                        </span>
                      )}
                      {/* VIEW BUTTON */}
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<RemoveRedEyeIcon />}
                        style={{
                          color: "#757ce8",
                          borderColor: "#757ce8",
                        }}
                        onClick={() => {
                          setViewItem(item);
                          setOpenViewDialog(true);
                        }}
                      >
                        View
                      </Button>
                      {isAuthenticated && (
                        <Box>
                          <Button
                            size="small"
                            variant="outlined"
                            aria-describedby={addPointPopoverId}
                            startIcon={<AddIcon />}
                            style={{
                              color: "#cad902",
                              borderColor: "#cad902",
                            }}
                            onClick={(e) => {
                              setAddPointId(item.id);
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            Add
                          </Button>
                          <Popover
                            id={addPointPopoverId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleAddPointClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            sx={{
                              "& .MuiPopover-paper": {
                                pt: 1,
                                pb: 1,
                                pl: 2,
                                pr: 1,
                              },
                            }}
                          >
                            <TextField
                              id="standard-number"
                              type="number"
                              label="Add Point"
                              variant="standard"
                              value={addPoint}
                              onChange={(e) => setAddPoint(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      size="small"
                                      style={{ background: "#cad902" }}
                                      onClick={() => {
                                        handleAddPoint();
                                      }}
                                    >
                                      <CheckIcon fontSize="string" />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Popover>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <StyledPagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          size="large"
        />
      </Box>
      <Box alignSelf="flex-end">
        <Button
          onClick={handleDownloadPDF}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          style={{ backgroundColor: "#A91720", color: "white" }}
        >
          Download PDF
        </Button>
      </Box>
      {/* EDIT DIALOG */}
      <Dialog
        open={openEditDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenEditDialog(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "flex-start",

            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "350px", // Set your width here
            },
          },
        }}
      >
        <DialogContent>
          <PlayerEditForm
            editItem={editItem}
            setEditItem={setEditItem}
            setOpenEditDialog={setOpenEditDialog}
          />
        </DialogContent>
      </Dialog>

      {/* VIEW DIALOG */}
      <Dialog
        open={openViewDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenViewDialog(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "flex-start",

            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            paddingBottom: "0px",
            paddingLeft: "10px",
            paddingRight: "15px",
          }}
        >
          <ViewPlayer viewItem={viewItem} />
        </DialogContent>
        <DialogActions>
          <Button
            fontSize="large"
            endIcon={<CloseIcon fontSize="large" />}
            sx={{ border: "1px solid blue" }}
            onClick={() => setOpenViewDialog(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RankingTable;
