import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import CreateIcon from "@mui/icons-material/Create";
import InputAdornment from "@mui/material/InputAdornment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { toast } from "react-toastify";
import usePlayerData from "../../hooks/usePlayerData";
function PlayerEditForm({ editItem, setEditItem, setOpenEditDialog }) {
  const { updatePlayerData } = usePlayerData();
  const closeEditDialog = () => {
    setOpenEditDialog(false);
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (editItem.phone_number.length !== 10) {
      toast.error(`Contact must be 10 digits`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    updatePlayerData(editItem, closeEditDialog);
  };
  const handleEditChange = (e) => {
    setEditItem({ ...editItem, [e.target.name]: e.target.value });
  };
  return (
    <form onSubmit={handleEditSubmit}>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        justifyContent="center"
      >
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Category
          </InputLabel>
          <NativeSelect
            value={editItem?.category}
            inputProps={{
              name: "category",
              id: "uncontrolled-native",
            }}
            onChange={handleEditChange}
          >
            <option value="all">All</option>
            <option value="mixedDouble">Mixed Doubles</option>
            <option value="mensSingle">Men's Single</option>
            <option value="womensSingle">Women's Single</option>
            <option value="mensDoubles">Men's Double</option>
            <option value="womensDoubles">Women's Double</option>
            <option value="U-17GirlsSingle">U-17 Girl’s Singles </option>
            <option value="U-17BoysSingle">U-17 Boy’s Singles</option>
            <option value="U-15GirlsSingle"> U-15 Girl’s Singles</option>
            <option value="U-15BoysSingle">U-15 Boy’s Singles</option>
          </NativeSelect>
        </FormControl>
        <TextField
          id="standard-basic"
          label="Name"
          name="name"
          variant="standard"
          value={editItem?.name || ""}
          onChange={handleEditChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ManageAccountsIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="standard-basic"
          label="Club/District"
          name="club"
          variant="standard"
          value={editItem?.club || ""}
          onChange={handleEditChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EditLocationAltIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="standard-basic"
          label="Points"
          variant="standard"
          name="point"
          value={editItem?.point || ""}
          onChange={handleEditChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <StarBorderIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="standard-number"
          type="number"
          label="Contact"
          variant="standard"
          name="phone_number"
          value={editItem?.phone_number || ""}
          onChange={handleEditChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PhonelinkSetupIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          style={{
            width: "100px",
            alignSelf: "center",
            backgroundColor: "#022362",
          }}
          endIcon={<CreateIcon />}
        >
          Edit
        </Button>
      </Box>
    </form>
  );
}

export default PlayerEditForm;
